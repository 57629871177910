.home-page {
    background-color: rgb(225, 225, 225);
    box-shadow: 0px 0px 8px 10px rgb(225, 225, 225);
    padding: 10px;
    line-height: 1.4;
}

.home-heading {
    font-family: 'Eczar', serif;
    text-align: center;
    font-size: 28px;
    color: #0CA01D;
    text-shadow: 0px 1px 1px black;
    margin-top: 5px;
    margin-bottom: 15px;
}

.home-page a {
    color: #0CA01D;
    font-weight: bold;
    font-size: 17px;
}

.home-intro {
    text-align: center;
    font-size: 16px;
    margin-top: 20px;
}

ul {
    margin-left: auto;
    margin-right: auto;
    width: 85%;
    font-size: 20px;
    font-weight: 600;
    text-shadow: 0px 1px 1px gray;
}

.question {
    font-family: 'Eczar', serif;
    text-align: center;
    font-size: 25px;
    color: #0CA01D;
    text-shadow: 0px 1px 1px black;
    margin-top: 25px;
    margin-bottom: 10px;
}

.pic-and-answer-box {
    display: flex;
    justify-content: center;
    align-items: center;
}

.answers-box img {
    width: 20%;
    max-width: 80px;
    margin-left: 20px;
    margin-right: 20px;
}

.answer {
    font-family: 'Eczar', serif;
    font-size: 25px;
    text-align: center;
    text-shadow: 0px 0px 2px #0CA01D;
}

.why {
    margin-bottom: 30px;
    text-align: center;
    font-size: 16px;
}

.home-outro {
    text-align: center;
    margin-top: 40px;
    font-size: 16px;
}

.info-and-map {
    display: flex;
    flex-direction: column;
}

@media screen and (min-width: 1000px) {
    .home-heading {
        font-size: 32px;
    }

    .home-intro {
        font-size: 18px;
    }

    .question {
        font-size: 32px;
    }

    .answer {
        font-size: 32px;
    }

    .why {
        font-size: 20px;
    }

    .home-outro {
        font-size: 20px;
    }

    .home-page a {
        font-size: 20px;
    }
}