h1, h2, h3, h4, p {
    margin: 0;
}

img:hover {
    transition: 1s;
    transform: scale(1.5);
}

a {
    color: white;
}