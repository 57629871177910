.map-component {
    text-align: center;
    margin-top: 50px;
}

.map-image {
    width: 80%;
    box-shadow: 0px 0px 8px 5px #0CA01D;
}

.map-image:hover {
    transition: 1s;
    transform: scale(1.2);
}