.gallery {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

.product-image {
    width: 25%;
    height: 25%;
    margin: 15px;
    box-shadow: 0px 0px 8px 6px white;
}