.info-box-component {
    background-color: #0CA01D;
    box-shadow: 0px 0px 8px 5px black;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.info-box-component a {
    color: white;
    text-shadow: 0px 1px 3px black;
}

.info-box-component a:visited {
    color: white;
}

.name-and-address {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
}

.info-title {
    font-family: 'Arbutus';
    text-align: center;
    font-size: 20px;
    color: white;
    text-shadow: 0px 5px 3px black;
}

.number {
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
}

.hours-operations {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
}

.hours-operations p {
    font-weight: bold;
    color: white;
    text-shadow: 0px 1px 3px black;
}

.link-to-map {
    text-decoration: none;
    font-weight: bold;
    padding: 10px;
    background: green;
    box-shadow: 0px 0px 8px 5px black;
}