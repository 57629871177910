.header {
    background-color: #0CA01D;
    width: 100%;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
    position: relative;
    padding-top: 5px;
    padding-bottom: 10px;
    box-shadow: 0px 0px 8px 10px white;
}

.store-name {
    font-family: 'Arbutus';
    font-size: 19px;
    color: white;
    text-shadow: 0px 5px 3px black;
    width: fit-content;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
}

.address-and-number {
    color: rgb(255, 0, 0);
    text-shadow: 0px 1px 3px black;
    font-size: 12px;
    width: fit-content;
    margin-left: 5px;
}

/* .address-and-number a:visited {
    color: white;
} */

/* .num-link {
    color: white;
} */

.hours {
    color: white;
    text-shadow: 0px 1px 3px black;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    width: fit-content;
    position: absolute;
    right: 5px;
    bottom: 0;
    margin-bottom: 10px;
}

@media screen and (min-width: 800px) {
    .header {
        margin-bottom: 75px;
    }

    .store-name {
        font-size: 40px;
    }

    .address-and-number {
        font-size: 22px;
        padding: 10px;
    }

    .hours {
        font-size: 22px;
        padding: 10px;
    }
}