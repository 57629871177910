.body-comp-page {
    background-color: rgb(225, 225, 225);
    box-shadow: 0px 0px 8px 10px rgb(225, 225, 225);
    padding: 10px;
    line-height: 1.4;
}

.body-comp-heading {
    font-family: 'Eczar', serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 22px;
    color: #0CA01D;
    text-shadow: 0px 1px 1px black;
    margin-top: 5px;
    margin-bottom: 15px;
    text-align: center;
}

.body-subheading {
    font-size: 18px;
}

.body-comp-intro-box {
    text-align: center;
    font-size: 16px;
}

.body-comp-intro1 {
    margin-bottom: 20px;
    font-size: 16px;
}

.body-comp-intro2 {
    margin-bottom: 20px;
    font-size: 16px;
}

.body-comp-intro3 {
    margin-bottom: 40px;
    font-size: 16px;
}

.body-comp-pics {
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.body-pic {
    width: 60%;
    align-self: center;
    margin-bottom: 30px;
    box-shadow: 0px 0px 8px 10px #0CA01D;
}

@media screen and (min-width: 800px) {
    .body-comp-pics {
        text-align: center;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
    }

    .body-pic {
        width: 40%;
    }

    .results:hover {
        transition: 1s;
        transform: scale(2);
    }
}

@media screen and (min-width: 1000px) {
    .body-comp-heading {
        font-size: 30px;
    }

    .body-subheading {
        font-size: 22px;
    }

    .body-comp-intro-box {
        font-size: 20px;
    }
}