.menu {
    width: 100%;
    height: 50px;
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 75px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #0CA01D;
    box-shadow: 0px 0px 8px 5px white;
}

.navlink {
    font-family: 'Eczar', serif;
    color: white;
    text-shadow: 0px 3px 3px black;
    font-size: 24px;
    font-weight: 500;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-decoration: none;
}

.navlink:hover {
    background-color: rgb(0, 100, 0);
    transition: .5s;
    box-shadow: 0px 0px 8px 5px white;
}

/* .navlink:visited {
    color: white;
} */