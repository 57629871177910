.smoothies-page {
    background-color: rgb(225, 225, 225);
    box-shadow: 0px 0px 8px 10px rgb(225, 225, 225);
    line-height: 1.4;
}

.smoothies-page-heading {
    font-family: 'Eczar', serif;
    text-align: center;
    font-size: 28px;
    color: #0CA01D;
    text-shadow: 0px 1px 1px black;
    margin-top: 5px;
    margin-bottom: 15px;
}

.smoothies-intro-box {
    text-align: center;
    font-size: 16px;
}

.smoothies-intro1 {
    margin-bottom: 25px;
    font-size: 16px;
}

.smoothies-intro2 {
    margin-bottom: 25px;
    font-size: 16px;
}

.smoothies-intro3 {
    margin-bottom: 10px;
    font-size: 16px;
}

.girl-scout-flavors {
    font-family: 'Eczar', serif;
    padding: 0;
    color: #0CA01D;
    text-shadow: 0px 1px 1px black;
    font-size: 28px;
    font-weight: bold;
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-bottom: 50px;
}

li {
    margin: 5px;
}

.smoothie-sizes {
    display: flex;
    justify-content: space-around;
    text-align: center;
    font-size: 20px;
    color: #0CA01D;
    text-shadow: 0px 1px 1px black;
}

.smoothies-section {
    display: flex;
}

.smoothie-heading {
    text-align: center;
}

.smoothies20-list {
    width: 50%;
}

.smoothies32-list {
    width: 50%;
    display: flex;
    flex-wrap: wrap;
}

@media screen and (min-width: 800px) {
    .smoothies20-list {
        display: flex;
        flex-wrap: wrap;
    }

    .smoothies32-list {
        display: flex;
        flex-wrap: wrap;
    }
}

@media screen and (min-width: 1000px) {
    .smoothies-page-heading {
        font-size: 35px;
    }

    .smoothies-intro-box {
        font-size: 20px;
    }
}