.supplements-page {
    background-color: rgb(225, 225, 225);
    box-shadow: 0px 0px 8px 10px rgb(225, 225, 225);
    padding: 10px;
    line-height: 1.4;
}

.supplements-heading {
    font-family: 'Eczar', serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 25px;
    color: #0CA01D;
    text-shadow: 0px 1px 1px black;
    margin-top: 5px;
    margin-bottom: 15px;
    text-align: center;
}

.supplements-subheading {
    font-size: 20px;
    text-align: center;
}

.supplements-intro-box {
    font-size: 16px;
}

.supplements-intro1 {
    text-align: center;
    font-size: 16px;
    margin-bottom: 20px;
}

.supplements-intro2 {
    text-align: center;
    font-size: 16px;
    margin-bottom: 40px;
}

.supplement-type {
    font-family: 'Eczar', serif;
    font-size: 30px;
    text-align: center;
    color: white;
    text-shadow: 0px 5px 3px black;
}

.supplement-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #0CA01D;
    box-shadow: 0px 0px 8px 10px black;
    margin-bottom: 40px;
}

.supplement-pics {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

@media screen and (min-width: 1000px) {
    .supplements-intro-box {
        font-size: 20px;
    }

    .supplements-heading {
        font-size: 32px;
    }

    .supplements-subheading {
        font-size: 24px;
    }
    .supplement-type {
        font-size: 50px;
        margin: 20px;
    }
}