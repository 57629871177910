.smoothie {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    background-color: #0CA01D;
    border: 1px solid black;
}

.smoothie-name {
    font-family: 'Eczar', serif;
    font-size: 25px;
    text-align: center;
    color: white;
    text-shadow: 0px 3px 1px black;
}

.size {
    color: white;
    text-shadow: 0px 2px 1px black;
}

.fact {
    border-bottom: 1px solid black;
    padding-left: 2%;
}

.small-fact {
    padding-left: 10%;
}

@media screen and (min-width: 800px) {
    .smoothie {
        width: 49%;
    }
}