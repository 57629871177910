.protein-page {
    background-color: rgb(225, 225, 225);
    box-shadow: 0px 0px 8px 10px rgb(225, 225, 225);
    padding: 10px;
    line-height: 1.4;
}

.protein-heading {
    font-family: 'Eczar', serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 25px;
    color: #0CA01D;
    text-shadow: 0px 1px 1px black;
    margin-top: 5px;
    margin-bottom: 15px;
}

.protein-subheading {
    font-size: 20px;
    text-align: center;
}

.protein-intro-box {
    font-size: 16px;
}

.protein-intro1 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 16px;
}

.protein-intro2 {
    text-align: center;
    margin-bottom: 40px;
    font-size: 16px;
}

.protein-cat-title {
    font-family: 'Eczar', serif;
    font-size: 30px;
    text-align: center;
    color: white;
    text-shadow: 0px 5px 3px black;
}

.protein-galleries {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.protein-category {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #0CA01D;
    box-shadow: 0px 0px 8px 10px black;
    margin-bottom: 40px;
}

.plant-protein {
    width: 50%;
    height: 50%;
    margin: 10px;
    box-shadow: 0px 0px 8px 5px white;
}

@media screen and (min-width: 1000px) {
    .protein-title {
        font-size: 60px;
    }

    .protein-subheading {
        font-size: 25px;
    }

    .protein-intro-box {
        font-size: 18px;
    }

    .protein-cat-title {
        font-size: 50px;
        margin: 20px;
    }
}