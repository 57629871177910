/* Position and sizing of burger button */
.bm-burger-button {
    position: absolute;
    width: 30px;
    height: 25px;
    left: 4%;
    top: 10px;
}
   
/* Color/shape of burger icon bars */
.bm-burger-bars {
    background: black;
    box-shadow: 0px 0px 5px 3px white;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
    background: white;
    box-shadow: 0px 0px 5px 3px #0CA01D;
}



/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
    position: fixed;
}

/* General sidebar styles */
.bm-menu {
    /* background: #0CA01D; */
    background: linear-gradient(90deg, rgba(12,160,29,1) 0%, rgba(0,0,0,1) 100%);
    font-size: 1.15em;
    padding: 5%;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill: white;
}

/* Wrapper for item list */
.bm-item-list {
    color: #ff0000;
    padding: 0.8em;
}

/* Individual item */
.bm-item {
    font-family: 'Eczar', serif;
    display: inline-block;
    padding: 10px;
    font-size: 22px;
    font-weight: bold;
    text-align: center;
    text-shadow: -5px 0px 8px rgb(0, 0, 0);
    background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(12,160,29,1) 100%);
    margin-bottom: 20px;
    text-decoration: none;
    box-shadow: 0px 0px 5px 3px white;
}

.bm-item:visited {
    color: white;
}

/* Styling of overlay */
.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
}

@media screen and (min-width: 800px) {
    .bm-burger-button {
        width: 40px;
        height: 35px;
    }
}