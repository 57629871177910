.room-and-noah {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 20px;
}

.cafe-main-pic {
    width: 45%;
    height: 45%;
    box-shadow: 0px 0px 8px 10px #0CA01D;
}

.wraps-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.wrap-pic {
    width: 20%;
    height: 20%;
    margin: 15px;
    box-shadow: 0px 0px 8px 10px #0CA01D;
}

.wrap-pic:hover {
    transition: 1s;
    transform: scale(2);
}